import React from "react";
import { IProgressBar } from "../../core/types/types";
import flavourConfig from "../../environment/flavourConfig";

const ProgressBar: React.FC<IProgressBar> = ({ progressState }: IProgressBar) => {
  return (
    <div className="w-9/12 lg:w-3/6 mx-auto">
      <div className="bg-gray-400 h-1 flex items-center justify-between">
        {/* LINE 0 - 1 */}
        <div
          className={`${
            progressState > 0 && "bg-" + flavourConfig.primaryColor
          } w-1/3 h-1 flex items-center relative`}
        >
          {/* TOOL TIP 0 */}
          <div className="hidden sm:block absolute -left-8 xl:-left-9">
            <div className="relative mt-16 -mr-12">
              <p
                className={`${
                  progressState >= 0 ? "text-" + flavourConfig.primaryColor : "text-gray-400"
                } progress-bar-font`}
              >
                Get Started
              </p>
            </div>
          </div>

          {/* DOT 0 */}
          <div className="bg-gray-400 h-7 w-7 rounded-full z-10 shadow flex items-center justify-center relative">
            <div
              className={`h-7 w-7 bg-${flavourConfig.primaryColor} rounded-full shadow flex items-center justify-center`}
            >
              <p className="text-white font-bold">1</p>
            </div>
          </div>
          {/* TOOL TIP 1 */}
          <div className="hidden sm:block absolute -right-11 xl:-right-14">
            <div className="relative mt-16 -mr-4">
              <p
                className={`${
                  progressState >= 1 ? "text-" + flavourConfig.primaryColor : "text-gray-400"
                } progress-bar-font`}
              >
                Enter Your Details
              </p>
            </div>
          </div>
        </div>
        {/*  LINE 1-2 */}
        <div
          className={`${
            progressState > 1 && "bg-" + flavourConfig.primaryColor
          } w-1/3 flex justify-between h-1 items-center relative`}
        >
          {/* DOT 1 */}
          <div className="bg-gray-400 h-7 w-7 rounded-full z-10 shadow flex items-center justify-center relative -ml-1">
            <div
              className={`${progressState >= 1 ? "h-7 w-7" : "h-0 w-0"} bg-${
                flavourConfig.primaryColor
              } rounded-full shadow flex items-center justify-center`}
            >
              <p className="text-white font-bold">2</p>
            </div>
          </div>
          {/* TOOL TIP 2 */}
          <div className="hidden sm:block absolute -right-9 xl:-right-12 -mr-0.5">
            <div className="relative mt-16 -mr-4">
              <p
                className={`${
                  progressState >= 2 ? "text-" + flavourConfig.primaryColor : "text-gray-400"
                } progress-bar-font`}
              >
                Add Your Items
              </p>
            </div>
          </div>
        </div>
        {/* DOT 2 */}
        <div className="bg-gray-400 h-7 w-7 rounded-full z-10 shadow flex items-center justify-center -mr-1 relative">
          <div
            className={`${progressState >= 2 ? "h-7 w-7" : "h-0 w-0"} bg-${
              flavourConfig.primaryColor
            } rounded-full shadow flex items-center justify-center absolute`}
          >
            <p className="text-white font-bold">3</p>
          </div>
        </div>
        {/* LINE 2 - 3 */}
        <div
          className={`${
            progressState > 2 && "bg-" + flavourConfig.primaryColor
          } w-1/3 flex justify-end h-1 items-center relative`}
        >
          {/* DOT 3 */}
          <div className="bg-gray-400 h-7 w-7 rounded-full shadow flex items-center justify-center relative -mr-1">
            <div
              className={`${progressState >= 3 ? "h-7 w-7" : "h-0 w-0"} bg-${
                flavourConfig.primaryColor
              } rounded-full flex items-center justify-center absolute`}
            >
              <p className="text-white font-bold">4</p>
            </div>
          </div>
          {/* TOOL TIP 3 */}
          <div className="hidden sm:block absolute right-2 xl:right-0.5">
            <div className="relative mt-16 -mr-10">
              <p
                className={`${
                  progressState >= 3 ? "text-" + flavourConfig.primaryColor : "text-gray-400"
                } progress-bar-font`}
              >
                Confirmation
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;

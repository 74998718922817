import { useState } from "react";

interface IDevLogin {
  updateLogin: Function;
}

const DevLogin: React.FC<IDevLogin> = ({ updateLogin }: IDevLogin) => {
  const [username, updateUsername] = useState("");
  const [password, updatePassword] = useState("");
  const [error, updateError] = useState("");
  const { REACT_APP_USERNAME } = process.env;
  const { REACT_APP_PASSWORD } = process.env;

  function handleSubmit() {
    if (username === REACT_APP_USERNAME && password === REACT_APP_PASSWORD) {
      updateLogin(true);
      return;
    }
    updateError("Incorrect username or password");
  }

  return (
    <div
      className="h-screen w-screen bg-gray-50 flex flex-col items-center justify-center"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
    >
      <form
        className="flex flex-col items-center px-8 py-6 background-white-ie-fix shadow-xl border border-gray-300 rounded-lg"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <input
          type="text"
          placeholder="Username"
          className="p-2 bg-gray-200 border border-black-true"
          onChange={(e) => updateUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="mt-2 p-2 bg-gray-200 border border-black-true"
          onChange={(e) => updatePassword(e.target.value)}
        />
        {error && <p className="mt-2 text-red-600">{error}</p>}
        <button type="submit" className="mt-4 px-4 py-2 border border-black-true ">
          Login
        </button>
      </form>
    </div>
  );
};

export default DevLogin;

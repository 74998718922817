import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiService } from "../../core/services/apiService";
import { Event, ICustomerForm } from "../../core/types/types";
import BusinessCustomerRoute from "../BusinessCustomerRoute/BusinessCustomerRoute";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";
import DotSpinner from "../DotSpinner/DotSpinner";
import flavourConfig from "../../environment/flavourConfig";

const CustomerForm: React.FC<ICustomerForm> = ({
  eventList,
  updateCustomerTicketNumber,
  customer,
  updateCustomer,
  isResidentialCustomer,
  updateIsResidentialCustomer,
  isValidAddress,
  updateIsValidAddress,
  hasCompletedWelcomePage,
  updateHasCompletedCustomerPage,
  hasTickedPrivacyPolicy,
  updateHasTickedPrivacyPolicy,
}: ICustomerForm) => {
  const history = useHistory();
  const [isLoading, updateIsLoading] = useState<boolean>(false);
  const [createCustomerError, updateCreateCustomerError] = useState<string | null>(null);

  // Form error messages
  const [residentialCustomerError, updateResidentialCustomerError] = useState<string | null>(null);
  const [addressErrorMessage, updateAddressErrorMessage] = useState<string | null>(null);

  // Scroll to top when component first mounts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // Prevent navigation to page unless correct path was taken
  useEffect(() => {
    if (!hasCompletedWelcomePage) {
      history.replace("/");
    }
  }, [history, hasCompletedWelcomePage]);

  // Main submit method
  const handleSubmit = async () => {
    updateIsLoading(true);
    updateCreateCustomerError(null);
    const response = await apiService.createCustomer(customer);

    if (response?.status === 200) {
      updateIsLoading(false);
      updateCustomerTicketNumber(response.data);
      updateHasCompletedCustomerPage(true);
      history.push("/waste-form");
      return;
    }

    updateIsLoading(false);
    updateCreateCustomerError(
      "We were unable to make this request. Please try again later or contact us on 0800 246 978."
    );
  };

  // Displays the event list within a <select> tag
  const renderEventList = (event: Event, index: number) => {
    let eventThresholdError = "";
    let isDisabled = false;

    if (event.percentageFull >= 100) {
      eventThresholdError = "(Event is at capacity. Bookings are now closed.)";
      isDisabled = true;
    } else if (event.percentageFull >= 80) {
      eventThresholdError = "(Event is nearly full.)";
    }

    return (
      <option
        value={event.eventID}
        key={index}
        disabled={isDisabled}
        className={`${isDisabled && "text-red-500"}`}>
        {`${event.eventName}, ${event.eventTime} on ${event.eventDate} ${eventThresholdError}`}
      </option>
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (isResidentialCustomer === null) {
          updateResidentialCustomerError("You must select one of the above options.");
          return;
        }
        handleSubmit();
      }}>
      <div className="w-11/12 mx-auto">
        <div className="container mx-auto">
          <div className="mx-auto xl:w-full xl:mx-0 xl:px-12">
            <div className="mb-0 mt-12 xl:mt-20 xl:flex lg:flex md:flex flex-wrap justify-between">
              <div className="mb-4 xl:mb-6 w-full flex flex-col items-start justify-center">
                <h1 className="body-heading-font">Enter Your Details</h1>
                <p className="body-subheading-font mt-2">
                  Please fill in the form below with your details. You can click on the information
                  icon in the top right corner if you need help.
                </p>
                <p className="pb-1.5 xl:pb-2 form-subheading-font mt-10 xl:mt-12">
                  Select a Customer Type<span className="text-red-600">*</span>
                </p>
                <div className="flex w-full justify-center">
                  <div className="bg-transparent border-2 border-gray-200 rounded flex items-center">
                    <button
                      type="button"
                      onClick={() => {
                        updateIsResidentialCustomer(true);
                        updateResidentialCustomerError(null);
                      }}
                      className={`border-r border-gray-200 text-center px-4 xl:px-10 py-3 lg:py-5 flex justify-center items-center cursor-pointer focus:bg-${
                        flavourConfig.inputColorLight
                      } hover:bg-${flavourConfig.inputColorLightest}
                        ${
                          isResidentialCustomer !== null &&
                          isResidentialCustomer &&
                          `bg-${flavourConfig.inputColorLight}`
                        }`}>
                      <p className="body-input-font">I'm a Residential Customer</p>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        updateIsResidentialCustomer(false);
                        updateResidentialCustomerError(null);
                      }}
                      className={`text-center px-4 xl:px-10 py-3 lg:py-5 flex justify-center items-center cursor-pointer focus:bg-${
                        flavourConfig.inputColorLight
                      } hover:bg-${flavourConfig.inputColorLightest}
                        ${
                          isResidentialCustomer !== null &&
                          !isResidentialCustomer &&
                          `bg-${flavourConfig.inputColorLight}`
                        }`}>
                      <p className="body-input-font">I'm a Business Customer</p>
                    </button>
                  </div>
                </div>
                {residentialCustomerError && (
                  <p className="body-error p-4 text-center w-full">{residentialCustomerError}</p>
                )}
              </div>
              {isResidentialCustomer === false ? (
                <BusinessCustomerRoute />
              ) : (
                <div className="w-full flex flex-col items-end">
                  <div className="w-full md:flex flex-wrap justify-between">
                    <div className="w-full flex flex-col mb-4 xl:mb-6">
                      <label htmlFor="event" className="pb-1.5 xl:pb-2 form-subheading-font">
                        Select an Event<span className="text-red-600">*</span>
                      </label>
                      <div className="border border-gray-300 shadow-sm rounded flex relative w-full">
                        <select
                          name="event"
                          required
                          id="event"
                          value={customer.Event === 0 ? "" : customer.Event}
                          className={`body-input-font background-white-ie-fix appearance-none z-10 py-3 pl-3 pr-12 overflow-ellipsis whitespace-nowrap overflow-x-hidden w-full border border-transparent focus:outline-none focus:border-${flavourConfig.inputColor} rounded`}
                          onChange={(e) =>
                            updateCustomer({ ...customer, Event: parseInt(e.target.value) })
                          }>
                          <option disabled value=""></option>
                          {eventList.map(renderEventList)}
                        </select>
                        <div
                          className="px-4 flex items-center border-l border-gray-300 flex-col justify-center text-gray-500
                        absolute right-0 bottom-0 top-0 mx-auto z-20 pointer-events-none ">
                          <svg
                            tabIndex={-1}
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-up"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <polyline points="6 15 12 9 18 15" />
                          </svg>
                          <svg
                            tabIndex={-1}
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-down"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <polyline points="6 9 12 15 18 9" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="xl:w-2/5 lg:w-2/5 md:w-2/5 flex flex-col mb-4 xl:mb-6">
                      <label htmlFor="Name" className="pb-1.5 xl:pb-2 form-subheading-font">
                        Full Name<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="Name"
                        value={customer.CustomerName}
                        required
                        id="Name"
                        className={`body-input-font border border-gray-300 pl-3 py-3 shadow-sm rounded focus:outline-none bg-transparent focus:border-${flavourConfig.inputColor}`}
                        onChange={(e) =>
                          updateCustomer({ ...customer, CustomerName: e.target.value })
                        }
                      />
                    </div>
                    <div className="xl:w-2/5 lg:w-2/5 md:w-2/5 flex flex-col mb-4 xl:mb-6">
                      <label
                        htmlFor="StreetAddress"
                        className="pb-1.5 xl:pb-2 form-subheading-font">
                        Street Address<span className="text-red-600">*</span>
                      </label>
                      <AddressAutocomplete
                        customer={customer}
                        addressErrorMessage={addressErrorMessage}
                        updateAddressErrorMessage={updateAddressErrorMessage}
                        updateCustomer={updateCustomer}
                        isValidAddress={isValidAddress}
                        updateIsValidAddress={updateIsValidAddress}
                      />
                      {addressErrorMessage && (
                        <p className="mt-2 body-error">{addressErrorMessage}</p>
                      )}
                    </div>
                    <div className="xl:w-2/5 lg:w-2/5 md:w-2/5 flex flex-col mb-4 xl:mb-6">
                      <label htmlFor="email2" className="pb-1.5 xl:pb-2 form-subheading-font">
                        Email Address<span className="text-red-600">*</span>
                      </label>
                      <div className="relative">
                        <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-mail"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x={3} y={5} width={18} height={14} rx={2} />
                            <polyline points="3 7 12 13 21 7" />
                          </svg>
                        </div>
                        <input
                          id="email2"
                          name="email"
                          type="email"
                          value={customer.CustomerEmail}
                          required
                          className={`w-full body-input-font bg-transparent border-gray-300 focus:outline-none focus:border-${flavourConfig.inputColor} font-normal py-3 flex items-center pl-16 rounded border shadow-sm`}
                          onChange={(e) =>
                            updateCustomer({ ...customer, CustomerEmail: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="xl:w-2/5 lg:w-2/5 md:w-2/5 flex flex-col mb-4 xl:mb-6">
                      <div className="flex items-center">
                        <label htmlFor="Number" className="pb-1.5 xl:pb-2 form-subheading-font">
                          Phone Number<span className="text-red-600">*</span>
                        </label>
                      </div>
                      <input
                        type="tel"
                        id="Number"
                        name="Number"
                        value={customer.CustomerPhone}
                        required
                        className={`border body-input-font pl-3 py-3 shadow-sm border-gray-300 rounded focus:outline-none bg-transparent focus:border-${flavourConfig.inputColor}`}
                        onChange={(e) =>
                          updateCustomer({ ...customer, CustomerPhone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex items-center pt-0 pb-4">
                    <input
                      type="checkbox"
                      name="privacy-policy"
                      id="privacy-policy"
                      className="cursor-pointer"
                      required
                      checked={hasTickedPrivacyPolicy}
                      onChange={(e) => {
                        updateHasTickedPrivacyPolicy(!hasTickedPrivacyPolicy);
                        (e.target as HTMLInputElement).setCustomValidity("");
                      }}
                      onInvalid={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity(
                          "You must agree to the privacy policy if you wish to continue."
                        )
                      }
                    />
                    <label htmlFor="privacy-policy" className="ml-2 form-subheading-font">
                      I have read and agree to the{" "}
                      <a
                        href="https://cms.environz.co.nz/media/Enviro%20NZ%20Privacy%20Policy.pdf"
                        className="underline"
                        target="_blank"
                        rel="noreferrer">
                        privacy policy
                      </a>
                      <span className="text-red-600">*</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {createCustomerError && (
          <p className="w-full text-center body-error">{createCustomerError}</p>
        )}
      </div>
      <div className="w-full py-4 sm:px-12 px-4 bg-gray-100 mt-6 rounded-b-lg border-t border-gray-200">
        <div className="w-11/12 flex justify-between mx-auto">
          <button
            type="button"
            className={`focus:outline-none w-24 xl:w-28 btn focus:border-${flavourConfig.inputColor} text-gray-600 border border-gray-300 py-2 mr-4 rounded hover:bg-gray-200 transition duration-150 ease-in-out`}
            onClick={() => history.replace("/")}>
            Cancel
          </button>
          <button
            disabled={
              isResidentialCustomer === false || addressErrorMessage !== null || !eventList.length
            }
            className={`w-24 xl:w-28 bg-${flavourConfig.secondaryColor} transition duration-150 ease-in-out hover:bg-${flavourConfig.secondaryColorLight} rounded text-white focus:bg-${flavourConfig.secondaryColorLight} focus:outline-none border focus:border-${flavourConfig.inputColor}`}
            type="submit">
            {isLoading ? <DotSpinner color="background-white-ie-fix" /> : "Next"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CustomerForm;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiService } from "../../core/services/apiService";
import { Event, IWelcomePage } from "../../core/types/types";
import flavourConfig from "../../environment/flavourConfig";
import DotSpinner from "../DotSpinner/DotSpinner";
import "../../styles/styles.css";

const WelcomePage: React.FC<IWelcomePage> = ({
  updateEventList,
  updateHasCompletedWelcomePage,
}: IWelcomePage) => {
  const history = useHistory();

  const [eventListErrorMessage, updateEventListErrorMessage] = useState<string | null>(null);
  const [isLoading, updateIsLoading] = useState<boolean>(false);

  // Event error messages for UI
  const eventErrors = {
    apiError:
      "We were unable to make this request. Please try again later or contact us on 0800 246 978.",
    noEventsError:
      "There are currently no collection events in your area. Please check again later or contact us on 0800 246 978.",
    eventsFullError:
      "All the events in your area are currently fully booked. Please check again later or contact us on 0800 246 978.",
  };

  // Scroll to top when component first mounts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // Fetches a list of events from API, checks for valid dates and booking availability
  const handleGetEvents = async () => {
    updateIsLoading(true);
    updateEventListErrorMessage(null);

    const response = await apiService.getEvents();

    if (response?.status === 200) {
      const validDateEvents = checkEventDates(response.data);
      if (validDateEvents.length) {
        if (checkEventCapacity(response.data).length) {
          updateIsLoading(false);
          updateEventList(validDateEvents);
          updateHasCompletedWelcomePage(true);
          history.push("/customer-form");
          return;
        } else {
          updateEventListErrorMessage(eventErrors.eventsFullError);
        }
      } else {
        updateEventListErrorMessage(eventErrors.noEventsError);
      }
    } else {
      updateEventListErrorMessage(eventErrors.apiError);
    }
    updateIsLoading(false);
  };

  const checkEventCapacity = (events: Event[]) => {
    return events.filter((event) => event.percentageFull <= 100);
  };

  // Returns events within valid date range
  const checkEventDates = (events: Event[]) => {
    const today = new Date().getTime();
    return events.filter((event) => {
      let eventStart = parseDate(event.startDate).getTime();
      let eventEnd = parseDate(event.endDate).getTime();
      return eventStart <= today && today < eventEnd;
    });
  };

  // Returns a date from a date string, i.e. 20/01/2021
  const parseDate = (date: string) => {
    const dateParts = date.split("/");
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  };

  return (
    <div className="w-full h-full pt-10 pb-14 sm:pt-20 sm:pb-20">
      <div className="grid grid-cols-3 pl-6 pr-6 sm:pl-16 sm:pr-20 lg:pl-20 xl:pl-28">
        <div className="col-span-full lg:col-span-2 flex flex-col items-center sm:items-start text-center sm:text-left">
          <h1 className="main-heading-font">
            Sign up for a Household <br />
            Hazardous Waste Drop-Off Event
          </h1>
          <p className="main-subheading-font mt-6 xl:mt-8">
            Simply add your details, select an upcoming drop-off event and you're good to go.
          </p>
          <button
            onClick={handleGetEvents}
            type="button"
            className={`bg-${flavourConfig.secondaryColor} hover:bg-${flavourConfig.secondaryColorLight} focus:outline-none border focus:border-${flavourConfig.inputColor} focus:bg-${flavourConfig.secondaryColorLight} w-44 xl:w-60 h-14 xl:h-16 mt-8 xl:mt-10 main-subheading-font transition duration-150 ease-in-out rounded text-white`}>
            {isLoading ? <DotSpinner color="background-white-ie-fix" /> : "Get Started"}
          </button>
          {eventListErrorMessage && <p className="mt-3 body-error">{eventListErrorMessage}</p>}
        </div>
        <div className="col-span-1 hidden lg:block">
          <img src="main-waste.jpg" alt="hazardous waste items" className="h-auto w-96" />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;

import axios, { AxiosResponse } from "axios";
import envConfig from "../../environment/envConfig";
import { Customer, WasteItem } from "../types/types";

axios.defaults.baseURL = envConfig.apiBaseUrl;

const getEvents = async (): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.get("chemwaste/getEvents", {
      headers: {
        "Content-Type": "application/json",
      },
      params:
        process.env.REACT_APP_ENVIRONMENT === "prod"
          ? { env: "prod", code: envConfig.keys.getEventsKey }
          : { env: "dev", code: envConfig.keys.getEventsKey },
    });
    return response;
  } catch (error) {
    return null;
  }
};

const createCustomer = async (customer: Customer): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("chemwaste/createCustomer", customer, {
      headers: {
        "Content-Type": "application/json",
        "x-functions-key": envConfig.keys.createCustomerKey,
      },
      params: process.env.REACT_APP_ENVIRONMENT === "prod" ? { env: "prod" } : { env: "dev" },
    });
    return response;
  } catch (error) {
    return null;
  }
};

const createCustomerLines = async (wasteItemList: WasteItem[]): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("chemwaste/createCustomerLines", wasteItemList, {
      headers: {
        "Content-Type": "application/json",
        "x-functions-key": envConfig.keys.createCustomerLinesKey,
      },
      params: process.env.REACT_APP_ENVIRONMENT === "prod" ? { env: "prod" } : { env: "dev" },
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const apiService = {
  getEvents,
  createCustomer,
  createCustomerLines,
};

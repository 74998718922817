import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IConfirmationPage } from "../../core/types/types";
import flavourConfig from "../../environment/flavourConfig";
import "../../styles/styles.css";

const Confirmation: React.FC<IConfirmationPage> = ({
  hasCompletedWastePage,
  customerTicketNumber,
}: IConfirmationPage) => {
  const history = useHistory();

  // Scroll to top when component first mounts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // Prevent navigation to page unless correct path was taken
  useEffect(() => {
    if (!hasCompletedWastePage) {
      history.replace("/");
    }
  }, [history, hasCompletedWastePage]);

  return (
    <div className="flex flex-col items-center sm:items-end text-center sm:text-right py-8 pr-6 pl-8 xl:py-20 xl:pr-28 xl:pl-52 2xl:pl-72">
      <h1 className="main-heading-font">You're all sorted!</h1>
      <p className={`main-subheading-font mt-6 xl:mt-8 font-bold`}>
        {`Your ticket number is #${customerTicketNumber}`}
      </p>
      <p className="main-subheading-font mt-3 xl:mt-4">
        We've sent you a confirmation email with your ticket number, event information and details
        of your hazardous waste. We'll let you know where the event is taking place at a date closer
        to the event. Please hold on to the confirmation email as you'll need to provide the ticket
        number on the day. If you can't find your confirmation email, please check your junk mail
        folder or contact us.
      </p>
      <button
        type="button"
        className={`w-44 lg:w-60 h-14 xl:h-16 mt-6 xl:mt-8 main-subheading-font bg-${flavourConfig.secondaryColor} transition duration-150 ease-in-out hover:bg-${flavourConfig.secondaryColorLight} rounded text-white focus:outline-none border focus:border-${flavourConfig.inputColor} focus:bg-${flavourConfig.secondaryColorLight}`}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Book Another Event
      </button>
    </div>
  );
};

export default Confirmation;

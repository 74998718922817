import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Nav from "./components/Nav/Nav";
import WasteForm from "./components/WasteForm/WasteForm";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import Confirmation from "./components/Confirmation/Confirmation";
import CustomerForm from "./components/CustomerForm/CustomerForm";
import WelcomePage from "./components/WelcomePage/WelcomePage";
import Footer from "./components/Footer/Footer";
import Tooltip from "./components/Tooltip/Tooltip";
import NotFound from "./components/NotFound/NotFound";
import envConfig from "./environment/envConfig";
import DevLogin from "./components/DevLogin/DevLogin";
import { Customer, Event } from "./core/types/types";
import flavourConfig from "./environment/flavourConfig";
import "./styles/styles.css";

const App: React.FC = () => {
  const location = useLocation();
  const today = new Date();
  const date = today.toLocaleDateString("en-NZ", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // For dev login
  const [isLoggedIn, updateLogIn] = useState(false);

  // State for correct route progression
  const [hasCompletedWelcomePage, updateHasCompletedWelcomePage] = useState(false);
  const [hasCompletedCustomerPage, updateHasCompletedCustomerPage] = useState(false);
  const [hasCompletedWastePage, updateHasCompletedWastePage] = useState(false);

  // State for events list
  const [eventList, updateEventList] = useState<Event[]>([]);

  // State for customer ticket number
  const [customerTicketNumber, updateCustomerTicketNumber] = useState(0);

  // State for the progress bar. Four pages == 0-3 state
  const [progressState, updateProgressState] = useState(0);

  // State for the tooltip that is displayed when info icon is clicked
  const [showTooltip, updateShowTooltip] = useState(false);

  // Customer form state
  const [customer, updateCustomer] = useState<Customer>({
    Event: 0,
    CustomerName: "",
    CustomerAddress: "",
    CustomerPhone: "",
    CustomerEmail: "",
    DateCreated: date,
  });

  // State for residential/business button
  const [isResidentialCustomer, updateIsResidentialCustomer] = useState<boolean | null>(null);

  // State for residential/business button
  const [hasTickedPrivacyPolicy, updateHasTickedPrivacyPolicy] = useState<boolean>(false);

  // State for validating address search field
  const [isValidAddress, updateIsValidAddress] = useState<boolean>(false);

  // Updates the progress bar state every time the page location changes
  useEffect(() => {
    let answer = 0;
    switch (location.pathname) {
      case "/":
        break;
      case "/customer-form":
        answer = 1;
        break;
      case "/waste-form":
        answer = 2;
        break;
      case "/confirmation":
        answer = 3;
        break;
    }
    updateProgressState(answer);
  }, [location]);

  return envConfig.requireLogin && !isLoggedIn ? (
    <DevLogin updateLogin={updateLogIn} />
  ) : (
    <div
      className={`bg-${flavourConfig.backgroundImage} bg-cover bg-top bg-fixed bg-no-repeat min-h-screen max-w-screen overflow-x-hidden`}
    >
      <Nav />
      <div className="min-h-screen flex flex-col justify-center pt-32 pb-32 lg:pt-40 lg:pb-40 w-11/12 lg:10/12 xl:9/12 2xl:w-8/12 mx-auto">
        <div className="background-white-ie-fix shadow-lg rounded-lg border border-gray-300">
          <div className="relative xl:w-full pt-10 pb-6 rounded-t-lg">
            <div className="flex items-center w-11/12 mx-auto">
              <ProgressBar progressState={progressState} />
              <div className="absolute right-1 top-2 sm:right-3 sm:top-3.5 text-gray-600">
                <Tooltip
                  showTooltip={showTooltip}
                  updateShowTooltip={updateShowTooltip}
                  pathname={location.pathname}
                />
              </div>
            </div>
          </div>
          <Switch>
            <Route exact path="/">
              <WelcomePage
                updateEventList={updateEventList}
                updateHasCompletedWelcomePage={updateHasCompletedWelcomePage}
              />
            </Route>
            <Route exact path="/customer-form">
              <CustomerForm
                eventList={eventList}
                updateCustomerTicketNumber={updateCustomerTicketNumber}
                customer={customer}
                updateCustomer={updateCustomer}
                isResidentialCustomer={isResidentialCustomer}
                updateIsResidentialCustomer={updateIsResidentialCustomer}
                isValidAddress={isValidAddress}
                updateIsValidAddress={updateIsValidAddress}
                hasCompletedWelcomePage={hasCompletedWelcomePage}
                updateHasCompletedCustomerPage={updateHasCompletedCustomerPage}
                hasTickedPrivacyPolicy={hasTickedPrivacyPolicy}
                updateHasTickedPrivacyPolicy={updateHasTickedPrivacyPolicy}
              />
            </Route>
            <Route exact path="/waste-form">
              <WasteForm
                customerTicketNumber={customerTicketNumber}
                hasCompletedCustomerPage={hasCompletedCustomerPage}
                updateHasCompletedWastePage={updateHasCompletedWastePage}
              />
            </Route>
            <Route exact path="/confirmation">
              <Confirmation
                hasCompletedWastePage={hasCompletedWastePage}
                customerTicketNumber={customerTicketNumber}
              />
            </Route>
            <NotFound />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;

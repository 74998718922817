import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import CircleSpinner from "../CircleSpinner/CircleSpinner";
import { IAddressAutocomplete } from "../../core/types/types";
import flavourConfig from "../../environment/flavourConfig";

const AddressAutocomplete: React.FC<IAddressAutocomplete> = ({
  updateCustomer,
  customer,
  addressErrorMessage,
  updateAddressErrorMessage,
  isValidAddress,
  updateIsValidAddress,
}: IAddressAutocomplete) => {
  const [isLoading, updateLoading] = useState(false);
  const errors = {
    addressNotFound:
      "We were unable to find your address. Please try again or contact us on 0800 246 978.",
    apiError:
      "We were unable to make this request. Please try again or contact us on 0800 246 978.",
    noAddressSelected: "You must select an address from the dropdown menu.",
  };

  // handles the selection of an address, checks for valid post codes
  async function handleSelect(value: string) {
    if (value === "") return;
    updateLoading(true);
    updateAddressErrorMessage(null);

    try {
      const [fullAddress] = await geocodeByAddress(value);
      const { long_name: postCode = "" } =
        fullAddress.address_components.find((c) => c.types.includes("postal_code")) || {};

      // fails if no postCode is found
      if (postCode === "") {
        updateLoading(false);
        updateAddressErrorMessage(errors.addressNotFound);
        return;
        // otherwise it succeeds, and postCode is passed on with full address (that includes postal code)
      } else {
        updateAddressErrorMessage(null);
        updateLoading(false);
        updateIsValidAddress(true);
        updateCustomer({ ...customer, CustomerAddress: fullAddress.formatted_address });
      }
    } catch (error) {
      updateLoading(false);
      updateAddressErrorMessage(errors.addressNotFound);
    }
  }

  // tab not automatically firing handleSelect so it must be done manually.
  function handleKeyPress(e: React.KeyboardEvent) {
    const key = e.key;
    if (key === "Tab" && customer.CustomerAddress !== "") {
      handleSelect((e.target as HTMLInputElement).value);
    }
  }

  // clicking on an address not setting address field value or firing handleSelect so both must be done manually.
  function handleClick(value: string) {
    updateCustomer({ ...customer, CustomerAddress: value });
    handleSelect(value);
  }

  // validation error message for addresses that haven't been run through postCodeCheck
  // i.e. a user enters an address and then clicks out of the dropdown
  function handleBlur(e: React.SyntheticEvent) {
    if (
      isLoading ||
      customer.CustomerAddress === "" ||
      isValidAddress ||
      addressErrorMessage !== null
    ) {
      return;
    }
    updateAddressErrorMessage(errors.noAddressSelected);
  }

  // restricts autofilled addresses to NZ only
  const searchOptions = {
    types: ["address"],
    componentRestrictions: { country: ["nz"] },
  };

  // classes for address box, suggestion boxes and final suggestion box
  const boxStyle = "body-input-font border border-gray-300 pl-3 py-3 w-full";
  const addressBox = `${boxStyle} shadow-sm pr-3 overflow-ellipsis whitespace-nowrap overflow-x-hidden rounded focus:outline-none focus:border-${flavourConfig.inputColor}`;
  const suggestionBoxes = `${boxStyle} bg-${flavourConfig.inputColorLightest} hover:bg-${flavourConfig.inputColorLight} flex flex-row items-center min-w-0 rounded-none border-l border-r border-b border-black-primary`;
  const firstBox = `${suggestionBoxes} rounded-t`;

  return (
    <div className="w-full h-full z-50">
      <PlacesAutocomplete
        value={customer.CustomerAddress}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        onChange={(value) => {
          updateAddressErrorMessage(null);
          updateIsValidAddress(false);
          updateCustomer({ ...customer, CustomerAddress: value });
        }}
      >
        {({ getInputProps, suggestions, loading }) => (
          <div
            className="relative w-full h-full"
            onKeyDown={(e) => handleKeyPress(e)}
            onBlur={(e) => handleBlur(e)}
          >
            <input
              data-lpignore="true"
              name="StreetAddress"
              id="StreetAddress"
              {...getInputProps({
                className: `${addressBox}`,
                autoComplete: "none",
                required: true,
              })}
            />
            {loading || isLoading ? (
              <div className="absolute right-1 bottom-1 top-1">
                <CircleSpinner />
              </div>
            ) : (
              <div></div>
            )}
            <div className="absolute w-full shadow-md">
              {suggestions.map((suggestion, i) => {
                const style = suggestion.active
                  ? `bg-${flavourConfig.inputColorLight} cursor-pointer`
                  : "cursor-pointer";
                return (
                  <div
                    key={i}
                    className={i === 0 ? `${firstBox} ${style}` : `${suggestionBoxes} ${style}`}
                    onMouseDown={() => handleClick(suggestion.description)}
                  >
                    <p className="w-full truncate">{suggestion.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressAutocomplete;

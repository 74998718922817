import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import WasteTable from "../WasteTable/WasteTable";
import { IWasteForm, WasteItem } from "../../core/types/types";
import { apiService } from "../../core/services/apiService";
import DotSpinner from "../DotSpinner/DotSpinner";
import flavourConfig from "../../environment/flavourConfig";

const WasteForm: React.FC<IWasteForm> = ({
  customerTicketNumber,
  hasCompletedCustomerPage,
  updateHasCompletedWastePage,
}: IWasteForm) => {
  const history = useHistory();
  const [isLoading, updateIsLoading] = useState<boolean>(false);
  const [createCustomerLinesError, updateCreateCustomerLinesError] = useState<string | null>(null);

  const wasteItemInitialState: WasteItem = {
    TicketNumber: customerTicketNumber,
    WasteType: "",
    Description: "",
    ContainerPackageSize: null,
    ContainerPackageSizeType: "KG",
    NumOfContainers: null,
    ApproximateAmount: null,
    ApproximateAmountType: "KG",
  };

  // State for the waste item table
  const [wasteItemList, updateWasteItemList] = useState<WasteItem[]>([]);

  // state for the waste item form
  const [wasteItem, updateWasteItem] = useState<WasteItem>(wasteItemInitialState);

  // Scroll to top when component first mounts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // Prevent navigation to page unless correct path was taken
  useEffect(() => {
    if (!hasCompletedCustomerPage) {
      history.replace("/");
    }
  }, [history, hasCompletedCustomerPage]);

  // Add items to the waste item table
  const handleAddItem = (e: React.FormEvent) => {
    e.preventDefault();
    updateCreateCustomerLinesError(null);
    updateWasteItemList(wasteItemList.concat(wasteItem));
    updateWasteItem(wasteItemInitialState);
  };

  // Remove items from the waste item table
  const handleRemoveWasteItem = (index: number) => {
    updateWasteItemList(wasteItemList.filter((item, i) => i !== index));
  };

  // Submit the entire form for DB consumption
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    updateIsLoading(true);
    updateCreateCustomerLinesError(null);
    const response = await apiService.createCustomerLines(wasteItemList);

    if (response?.data === "Success") {
      updateIsLoading(false);
      updateHasCompletedWastePage(true);
      history.push("/confirmation");
      return;
    }

    updateIsLoading(false);
    updateCreateCustomerLinesError(
      "We were unable to make this request. Please try again later or contact us on 0800 246 978."
    );
  };

  // Handles onChange methods for containerSize, noOfContainers and approxAmount, also calculates approxAmount if valid.
  const handleAmountChanges = (value: number, inputId: string) => {
    switch (inputId) {
      case "containerSize":
        if (wasteItem.NumOfContainers) {
          updateWasteItem({
            ...wasteItem,
            ContainerPackageSize: value,
            ApproximateAmount: value * wasteItem.NumOfContainers,
          });
        } else {
          updateWasteItem({ ...wasteItem, ContainerPackageSize: value });
        }
        break;
      case "noOfContainers":
        if (wasteItem.ContainerPackageSize) {
          updateWasteItem({
            ...wasteItem,
            NumOfContainers: value,
            ApproximateAmount: value * wasteItem.ContainerPackageSize,
          });
        } else {
          updateWasteItem({ ...wasteItem, NumOfContainers: value });
        }
        break;
    }
  };

  return (
    <div>
      <div className="w-10/12 mx-auto mt-12 xl:mt-20">
        <form className="" id="waste-form" onSubmit={(e) => handleAddItem(e)}>
          <div className="mb-10 xl:w-full">
            <h1 className="body-heading-font">Add Your Items</h1>
            <p className="body-subheading-font mt-2">
              Add the items you intend to bring to the household hazardous waste disposal event.
            </p>
            <div className="mt-10 mb-3 grid sm:grid-cols-9 gap-x-3 xl:gap-x-4 gap-y-2">
              <div className="flex flex-col col-span-full lg:col-span-3">
                <label htmlFor="event" className="pb-1 form-subheading-font">
                  Waste type<span className="text-red-600">*</span>
                </label>
                <div className="border border-gray-300 shadow-sm rounded flex relative lg:mr-5">
                  <select
                    id="wasteType"
                    name="wasteType"
                    title="Select the type of your waste item."
                    required
                    value={wasteItem.WasteType}
                    className={`body-input-font background-white-ie-fix appearance-none z-10 pl-3 py-3 w-full border border-transparent focus:outline-none focus:border-${flavourConfig.inputColor} rounded`}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      updateWasteItem({ ...wasteItem, WasteType: e.target.value })
                    }>
                    <option disabled value=""></option>
                    <option value="Paint Waste">Paint Waste</option>
                    <option value="Solvents">Solvents</option>
                    <option value="Oils & automotive">Oils & automotive</option>
                    <option value="Household chemicals">Household chemicals</option>
                    <option value="Aerosols">Aerosols</option>
                    <option value="Pesticides (Herbicides, Fungicides, and Insecticides)">Pesticides (Herbicides, Fungicides, and Insecticides)</option>
                  </select>
                  <div className="w-16 flex flex-col justify-center items-center border-l border-gray-300 text-gray-500 absolute right-0 bottom-0 top-0 mx-auto z-20 pointer-events-none">
                    <svg
                      tabIndex={-1}
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-chevron-up"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <polyline points="6 15 12 9 18 15" />
                    </svg>
                    <svg
                      tabIndex={-1}
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-chevron-down"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start sm:col-span-3 lg:col-span-2">
                <label htmlFor="event" className="pb-1 form-subheading-font">
                  Container size<span className="text-red-600">*</span>
                </label>
                <div className="flex flex-row items-center w-full">
                  <div className="relative w-full sm:mr-4">
                    <div className="w-16 absolute body-input-font text-gray-600 flex items-center border-l right-0 h-full">
                      <select
                        className="w-full h-full pl-2.5 uppercase text-sm leading-tight tracking-normal appearance-none z-20 relative bg-transparent"
                        value={wasteItem.ContainerPackageSizeType}
                        tabIndex={0}
                        onChange={(e) =>
                          updateWasteItem({
                            ...wasteItem,
                            ContainerPackageSizeType: e.target.value,
                            ApproximateAmountType: e.target.value,
                          })
                        }>
                        <option value="KG">KG</option>
                        <option value="L">L</option>
                      </select>
                      <div className="pr-2.5 absolute -right-0 z-10 text-gray-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-chevron-down"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="6 9 12 15 18 9" />
                        </svg>
                      </div>
                    </div>
                    <input
                      id="containerSize"
                      type="number"
                      title="Add the container/package size of your waste item."
                      required
                      className={`w-full body-input-font pr-16 overflow-ellipsis whitespace-nowrap overflow-x-hidden focus:outline-none focus:border focus:border-${flavourConfig.inputColor} background-white-ie-fix flex items-center pl-3 py-3 border-gray-300 rounded border shadow-sm`}
                      value={
                        !wasteItem.ContainerPackageSize && wasteItem.ContainerPackageSize !== 0
                          ? ""
                          : wasteItem.ContainerPackageSize
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleAmountChanges(parseFloat(e.target.value), e.target.id)
                      }
                    />
                  </div>
                  <p className="body-input-font text-lg hidden sm:block">x</p>
                </div>
              </div>
              <div className="flex flex-col items-start sm:col-span-3 lg:col-span-2">
                <label htmlFor="event" className="pb-1 form-subheading-font">
                  No. of containers<span className="text-red-600">*</span>
                </label>
                <div className="flex flex-row items-center w-full">
                  <input
                    type="number"
                    id="noOfContainers"
                    name="noOfContainers"
                    title="Add the number of containers/packages that you have of this waste item."
                    required
                    className={`w-full sm:mr-4 body-input-font border border-gray-300 pl-3 py-3 shadow-sm rounded focus:outline-none bg-transparent focus:border-${flavourConfig.inputColor}`}
                    value={
                      !wasteItem.NumOfContainers && wasteItem.NumOfContainers !== 0
                        ? ""
                        : wasteItem.NumOfContainers
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleAmountChanges(parseFloat(e.target.value), e.target.id)
                    }
                  />
                  <p className="body-input-font hidden sm:block text-xl">{"\u2248"}</p>
                </div>
              </div>
              <div className="flex flex-col sm:col-span-3 lg:col-span-2">
                <label htmlFor="event" className="pb-1 form-subheading-font">
                  Approx. total amount<span className="text-red-600">*</span>
                </label>
                <div className="relative">
                  <div className="w-16 absolute body-input-font text-gray-600 flex items-center border-l right-0 h-full">
                    <select
                      className="pl-2.5 h-full w-full uppercase text-sm leading-tight tracking-normal appearance-none z-20 relative bg-transparent"
                      value={wasteItem.ApproximateAmountType}
                      onChange={(e) =>
                        updateWasteItem({
                          ...wasteItem,
                          ApproximateAmountType: e.target.value,
                          ContainerPackageSizeType: e.target.value,
                        })
                      }>
                      <option value="KG">KG</option>
                      <option value="L">L</option>
                    </select>
                    <div className="pr-2.5 absolute right-0 z-10 text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-chevron-down"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </div>
                  </div>
                  <input
                    id="approximateAmount"
                    type="number"
                    title="Add the approximate total amount that you have of this waste item."
                    required
                    className={`w-full body-input-font pr-16 overflow-ellipsis whitespace-nowrap overflow-x-hidden focus:outline-none focus:border focus:border-${flavourConfig.inputColor} background-white-ie-fix flex items-center pl-3 py-3 border-gray-300 rounded border shadow-sm`}
                    value={
                      !wasteItem.ApproximateAmount && wasteItem.ApproximateAmount !== 0
                        ? ""
                        : wasteItem.ApproximateAmount
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateWasteItem({
                        ...wasteItem,
                        ApproximateAmount: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col col-span-full">
                <label htmlFor="event" className="pb-1 form-subheading-font">
                  Description<span className="text-red-600">*</span>
                </label>
                <div className="relative">
                  <input
                    id="Description"
                    name="Description"
                    title="Add a short description of your waste item."
                    required
                    className={`w-full body-input-font bg-transparent focus:outline-none focus:border focus:border-${flavourConfig.inputColor} font-normal py-3 flex items-center pl-3 rounded border border-gray-300 shadow-sm`}
                    value={wasteItem.Description}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateWasteItem({ ...wasteItem, Description: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                form="waste-form"
                className={`py-2 w-24 xl:w-28 bg-${flavourConfig.primaryColor} transition duration-150 ease-in-out hover:bg-${flavourConfig.primaryColorLight} rounded text-white focus:bg-${flavourConfig.primaryColorLight} focus:outline-none border focus:border-${flavourConfig.inputColor}`}>
                Add Item
              </button>
            </div>
          </div>
        </form>
        {wasteItemList && (
          <WasteTable wasteItemList={wasteItemList} handleRemoveWasteItem={handleRemoveWasteItem} />
        )}
        {createCustomerLinesError && (
          <div className="w-11/12 mx-auto py-0 xl:py-4 mb-8">
            <p className="w-full text-center mt-2 body-error">{createCustomerLinesError}</p>
          </div>
        )}
      </div>
      <div className="w-full mt-12 bg-gray-100 rounded-b-lg border-t border-gray-200">
        <div className="w-10/12 mx-auto py-4 flex justify-between">
          <button
            type="button"
            className={`focus:outline-none w-24 xl:w-28 btn focus:border-${flavourConfig.primaryColor} text-gray-600  border border-gray-300 py-2 mr-4 rounded hover:bg-gray-200 transition duration-150 ease-in-out`}
            onClick={() => history.goBack()}>
            Back
          </button>
          <button
            type="button"
            className={`w-24 xl:w-28 bg-${flavourConfig.secondaryColor} transition duration-150 ease-in-out hover:bg-${flavourConfig.secondaryColorLight} rounded text-white focus:bg-${flavourConfig.secondaryColorLight} focus:outline-none border focus:border-${flavourConfig.inputColor}`}
            onClick={(e: React.SyntheticEvent) => {
              if (!wasteItemList.length) {
                updateCreateCustomerLinesError("You haven't added any waste items yet.");
                return;
              }
              handleSubmit(e);
            }}>
            {isLoading ? <DotSpinner color="background-white-ie-fix" /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WasteForm;

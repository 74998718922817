import { defaultBackgroundImage } from "../core/constants/constants";

const hamilton = {
  logo: "hamilton-logo.png",
  backgroundImage: defaultBackgroundImage,
  primaryColor: "hamilton-primary",
  primaryColorLight: "hamilton-primary-lighter",
  secondaryColor: "hamilton-secondary",
  secondaryColorLight: "hamilton-secondary-lighter",
  inputColor: "gray-600",
  inputColorHover: "gray-500",
  inputColorLight: "gray-200",
  inputColorLightest: "gray-100",
};

const waikato = {
  logo: "waikato-logo.png",
  backgroundImage: defaultBackgroundImage,
  primaryColor: "waikato-primary",
  primaryColorLight: "waikato-primary-lighter",
  secondaryColor: "waikato-secondary",
  secondaryColorLight: "waikato-secondary-lighter",
  inputColor: "gray-600",
  inputColorHover: "gray-500",
  inputColorLight: "gray-200",
  inputColorLightest: "gray-100",
};

const defaultCouncil = {
  logo: "",
  backgroundImage: defaultBackgroundImage,
  primaryColor: "indigo-700",
  primaryColorLight: "indigo-600",
  secondaryColor: "hamilton-green",
  secondaryColorLight: "hamilton-green-lighter",
  inputColor: "indigo-700",
  inputColorHover: "indigo-600",
  inputColorLight: "indigo-200",
  inputColorLightest: "indigo-100",
};

const flavourConfig =
  process.env.REACT_APP_FLAVOUR === "hamilton"
    ? hamilton
    : process.env.REACT_APP_FLAVOUR === "waikato"
    ? waikato
    : defaultCouncil;

export default flavourConfig;

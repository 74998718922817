import React from "react";
import flavourConfig from "../../environment/flavourConfig";
import "../../styles/styles.css";

const Nav: React.FC = () => {
  return (
    <div className="fixed top-0 border-b border-gray-300 background-white-ie-fix z-50 py-4 w-full shadow-sm">
      <div className="mx-auto w-11/12 lg:10/12 xl:9/12 2xl:w-8/12 flex flex-row items-center justify-center lg:justify-start">
        <img src={flavourConfig.logo} alt="" className="h-16 lg:h-18 xl:h-20" />
      </div>
    </div>
  );
};

export default Nav;
